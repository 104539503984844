import "../styles/typography.css";

export default {
  colors: {
    background: '#fcf5e9',
    text: 'black',
    primary: '#fcf5e9',
    secondary: '#ddd7da',
    tertiary: '#fbfbfb',
    highlight: '#fbfbfb',
  },
  fonts: {
    body:
      '"Freight Text W03 Book", "Noto Serif SC", georgia, "Songti SC", Simsun, serif',
    navigation:
      'Chivo, sans-serif',
    title:
      '"Freight Display W03 Book", "Noto Serif SC", georiga, "Songti SC", Simsun, serif',
  },
  sizes: {
    maxWidth: '1020px',
    maxWidthCentered: '750px',
  },
  responsive: {
    small: '350px',
    medium: '720px',
    large: '1080px',
  },
}
